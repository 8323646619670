import React from 'react'
import { Link } from 'gatsby'
import Layout from '../layouts/product'
import { Container, Header } from 'semantic-ui-react'

const AboutUs = () => (
  <Layout>
    <Container style={{ marginTop: '14vh' }}>
      <Container textAlign="justified">
        <Header as="h1">About Us</Header>
        <p>
          Thanks for checking out our store. 'Make America HATS Again' is a
          collection of unique and original products designed to start a
          conversation. All of our hats are made in America, by American
          workers, and then embroidered with our designs. You can feel confident
          that every hat purchased from us goes towards keeping jobs in America
          as a principal rather than just a slogan.
        </p>

        <p>
          Our hats are made to order and then sent out from our embroidery
          facility. They can take approximately five business days to create and
          then a few days to get to you.
        </p>

        <p>
          Our site is secured with https, all payments are processed through
          Stripe.com and as such your payment information is never sent directly
          to us. With this method your credit card information is kept private
          and secure. This ensures we are fully PCI compliant with regard to all
          of your credit card data.
        </p>

        <p>
          If you have any questions, general comments or suggestions about the
          site or are looking for a custom design or larger order, please feel
          free to
          <Link to="/contact-us"> Contact Us</Link>.
        </p>
      </Container>
    </Container>
  </Layout>
)

export default AboutUs
